import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';

const PublicPaymentForm = () => {
    const { hash } = useParams();
    const [form, setForm] = useState(null);
    const [formData, setFormData] = useState({});
    const [calculatedPrice, setCalculatedPrice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [totalPrice, setTotalPrice] = useState(null);
    const [accessCode, setAccessCode] = useState('');
    const [showAccessCodeForm, setShowAccessCodeForm] = useState(false);
    const [requiresAccessCode, setRequiresAccessCode] = useState(false);
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
    const [accessCodeError, setAccessCodeError] = useState('');
    const [customerData, setCustomerData] = useState({
        email: '',
        name: ''
    });

    useEffect(() => {
        const fetchForm = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/paymentforms/${hash}`);
                
                if (!response.ok) {
                    throw new Error('No s\'ha pogut carregar el formulari');
                }

                const data = await response.json();
                
                if (data.requires_access_code) {
                    setRequiresAccessCode(true);
                    setForm(null);
                } else {
                    initializeFormWithData(data);
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchForm();
    }, [hash]);

    const initializeFormWithData = (formData) => {
        setForm(formData);
        if (formData && formData.sections) {
            setFormData(initializeFormData());
        }
    };

    const getFieldId = (sectionIndex, fieldName) => {
        if (!form || !form.sections) {
            console.log('Formulario no inicializado completamente');
            return null;
        }
        
        const section = form.sections[sectionIndex];
        if (!section) {
            console.log('Sección no encontrada:', sectionIndex);
            return null;
        }
        
        return `${section.id}-${fieldName}`;
    };

    const initializeFormData = () => {
        if (!form || !form.sections) {
            console.log('Esperando carga del formulario...');
            return {};
        }

        const initialData = {};
        form.sections.forEach((section, sectionIndex) => {
            section.fields.forEach(field => {
                const fieldId = getFieldId(sectionIndex, field.name);
                if (fieldId) {
                    initialData[fieldId] = '';
                }
            });
        });
        return initialData;
    };

    const evaluateSimpleCondition = (condition, formData) => {
        console.log('Evaluando condición simple:', {
            condition,
            formData
        });

        // Verificar si existe field_id
        if (!condition?.field_id) {
            console.log('No hay field_id en la condición');
            return true; // Si no hay field_id, asumimos que el campo es visible
        }

        const [sectionId, fieldName] = condition.field_id.split('-');
        const fieldId = `${sectionId}-${fieldName}`;
        
        console.log('Field ID construido:', fieldId);

        if (!formData[fieldId]) {
            console.log('Campo no encontrado:', fieldId);
            return true; // Si no hay valor, asumimos que el campo es visible
        }

        const fieldValue = formData[fieldId];
        const compareValue = condition.value;
        const operator = condition.operator?.toLowerCase() || 'equals';

        const result = (() => {
            switch (operator) {
                case 'equals':
                    return fieldValue == compareValue;
                case 'not_equals':
                    return fieldValue != compareValue;
                case 'greater_than':
                    return parseFloat(fieldValue) > parseFloat(compareValue);
                case 'less_than':
                    return parseFloat(fieldValue) < parseFloat(compareValue);
                case 'is_empty':
                    return !fieldValue || fieldValue.length === 0;
                case 'is_not_empty':
                    return fieldValue && fieldValue.length > 0;
                default:
                    return false;
            }
        })();

        console.log('Resultado evaluación:', {
            operator,
            fieldValue,
            compareValue,
            result
        });

        return result;
    };

    const isFieldVisible = (field) => {
        if (!field.visibility_rules || !field.visibility_rules.enabled) {
            return field.visible ?? true;
        }

        return evaluateSimpleCondition(field.visibility_rules, formData);
    };

    const handleInputChange = async (fieldId, value, fieldName) => {
        console.log('Campo modificado:', {
            fieldId,
            value,
            fieldName,
            previousFormData: {...formData}
        });

        const newFormData = { ...formData, [fieldId]: value };
        setFormData(newFormData);
        
        console.log('Nuevo estado del formulario:', newFormData);

        const priceAffectingFields = [
            "Edat",
            "Nacionalitat",
            "Estudis",
            "TipusExempcio"
        ];

        if (priceAffectingFields.includes(fieldName)) {
            await calculatePrice(newFormData);
        }
    };

    const calculatePrice = async (data) => {
        let newPrice = parseFloat(form.base_price);

        form.conditions.sort((a, b) => a.priority - b.priority).forEach(condition => {
            if (!condition.is_active) return;

            let conditionMet = false;
            if (condition.rules.operator === "AND") {
                conditionMet = condition.rules.conditions.every(rule => {
                    const fieldValue = data[getFieldId(findSectionIndexByFieldName(rule.field), rule.field)];
                    return evaluateCondition(rule, fieldValue);
                });
            } else if (condition.rules.operator === "OR") {
                conditionMet = condition.rules.conditions.some(rule => {
                    const fieldValue = data[getFieldId(findSectionIndexByFieldName(rule.field), rule.field)];
                    return evaluateCondition(rule, fieldValue);
                });
            }

            if (conditionMet) {
                condition.actions.price_modifications.forEach(mod => {
                    if (mod.type === 'fixed') {
                        if (mod.operation === 'add') {
                            newPrice += parseFloat(mod.value);
                        } else if (mod.operation === 'subtract') {
                            newPrice -= parseFloat(mod.value);
                        }
                    } else if (mod.type === 'percentage') {
                        const modificationAmount = (newPrice * parseFloat(mod.value)) / 100;
                        if (mod.operation === 'add') {
                            newPrice += modificationAmount;
                        } else if (mod.operation === 'subtract') {
                            newPrice -= modificationAmount;
                        }
                    }
                });
            }
        });

        setTotalPrice(Math.max(0, newPrice));
    };

    const findSectionIndexByFieldName = (fieldName) => {
        for (let i = 0; i < form.sections.length; i++) {
            if (form.sections[i].fields.some(field => field.name === fieldName)) {
                return i;
            }
        }
        return 0;
    };

    const evaluateCondition = (rule, fieldValue) => {
        if (!fieldValue) return false;
        
        switch (rule.operator) {
            case 'equals':
                return fieldValue === rule.value;
            case 'not_equals':
                return fieldValue !== rule.value;
            case 'greater_than':
                return parseFloat(fieldValue) > parseFloat(rule.value);
            case 'less_than':
                return parseFloat(fieldValue) < parseFloat(rule.value);
            default:
                return false;
        }
    };

    const validateForm = () => {
        const errors = {};
        
        // Validar campos del formulario
        form.sections.forEach((section, sectionIndex) => {
            // Si la sección está oculta, ignoramos sus campos
            if (!isSectionVisible(section, sectionIndex)) {
                return;
            }

            section.fields.forEach(field => {
                // Si el campo está oculto, ignoramos su validación
                if (!isFieldVisible(field)) {
                    return;
                }

                const fieldId = getFieldId(sectionIndex, field.name);
                if (field.required && (!formData[fieldId] || formData[fieldId].trim() === '')) {
                    errors[fieldId] = `${field.label} es obligatorio`;
                }
            });
        });

        // Validar email del cliente
        if (!customerData.email) {
            errors.customerEmail = 'El correo electrónico es obligatorio';
        } else if (!/\S+@\S+\.\S+/.test(customerData.email)) {
            errors.customerEmail = 'El correo electrónico no es válido';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Filtrar los datos del formulario para solo enviar campos visibles
        const visibleFormData = {};
        form.sections.forEach((section, sectionIndex) => {
            if (isSectionVisible(section, sectionIndex)) {
                section.fields.forEach(field => {
                    if (isFieldVisible(field)) {
                        const fieldId = getFieldId(sectionIndex, field.name);
                        visibleFormData[fieldId] = formData[fieldId];
                    }
                });
            }
        });

        try {
            const paymentData = {
                amount: totalPrice,
                recipient: {
                    email: customerData.email,
                    name: customerData.name || null
                },
                form_data: visibleFormData
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/paymentforms/${hash}/process-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error al processar el pagament');
            }

            if (data.success) {
                // Gestionar la resposta de la passarel·la de pagament
                const gatewayResponse = data.data;

                if (gatewayResponse.method === 'POST') {
                    // Crear i enviar formulari de redirecció
                    const form = document.createElement('form');
                    form.method = gatewayResponse.method;
                    form.action = gatewayResponse.url;
                    form.style.display = 'none';

                    Object.entries(gatewayResponse.fields || {}).forEach(([key, value]) => {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    });

                    document.body.appendChild(form);
                    form.submit();
                } else if (gatewayResponse.method === 'GET') {
                    window.location.href = gatewayResponse.url;
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setFormErrors({
                submit: error.message || 'Error al processar el pagament'
            });
        }
    };

    const handleAccessCodeSubmit = async (e) => {
        e.preventDefault();
        setAccessCodeError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/paymentforms/${hash}/verify-access-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ access_code: accessCode })
            });

            const data = await response.json();

            if (response.ok) {
                setIsAccessCodeValid(true);
                initializeFormWithData(data);
            } else {
                setAccessCodeError(data.error || 'Codi d\'accés incorrecte');
            }
        } catch (error) {
            setAccessCodeError('Error en validar el codi d\'accés');
        }
    };

    const handleCustomerDataChange = (e) => {
        setCustomerData({
            ...customerData,
            [e.target.name]: e.target.value
        });
    };

    const renderField = (field, sectionIndex) => {
        const fieldId = getFieldId(sectionIndex, field.name);
        
        switch (field.type) {
            case 'text':
            case 'email':
                return (
                    <input
                        type={field.type}
                        value={formData[fieldId] || ''}
                        onChange={(e) => handleInputChange(fieldId, e.target.value, field.name)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        placeholder={field.placeholder}
                    />
                );
            case 'number':
                return (
                    <input
                        type="number"
                        value={formData[fieldId] || ''}
                        onChange={(e) => handleInputChange(fieldId, e.target.value, field.name)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        value={formData[fieldId] || ''}
                        onChange={(e) => handleInputChange(fieldId, e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        placeholder={field.placeholder}
                        rows="4"
                    />
                );
            case 'select':
                return (
                    <select
                        value={formData[fieldId] || ''}
                        onChange={(e) => handleInputChange(fieldId, e.target.value, field.name)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    >
                        <option key={`${field.id}-default`} value="">
                            Selecciona una opció
                        </option>
                        {Array.isArray(field.options) && field.options.map((option, index) => (
                            <option 
                                key={`${field.id}-${option.value || index}`} 
                                value={option.value || option}
                            >
                                {option.label || option}
                            </option>
                        ))}
                    </select>
                );
            case 'checkbox':
                return (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={formData[fieldId] || false}
                            onChange={(e) => handleInputChange(fieldId, e.target.checked)}
                            className="h-4 w-4 text-[#ED5B52] focus:ring-[#ED5B52] border-gray-300 rounded"
                        />
                        <span className="ml-2 text-gray-700">{field.label}</span>
                    </div>
                );
            default:
                return null;
        }
    };

    const layoutProps = {
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    const isSectionVisible = (section, currentSectionIndex) => {
        console.log('Evaluando visibilidad de sección:', {
            sectionName: section.name,
            currentSectionIndex,
            rules: section.visibility_rules
        });

        if (!section.visibility_rules || !section.visibility_rules.enabled) {
            return true;
        }

        const rules = section.visibility_rules;
        const [sectionIndex, fieldName] = rules.field_id.split('-');
        
        // Obtener el valor usando el índice de la sección
        const targetSection = form.sections[parseInt(sectionIndex)];
        if (!targetSection) {
            console.log('Sección no encontrada para índice:', sectionIndex);
            return true;
        }

        const field = targetSection.fields.find(f => f.name === fieldName);
        if (!field) {
            console.log('Campo no encontrado:', fieldName);
            return true;
        }

        const fieldId = `${targetSection.id}-${fieldName}`;
        const fieldValue = formData[fieldId];

        console.log('Evaluando condición:', {
            fieldId,
            fieldValue,
            compareValue: rules.value,
            operator: rules.operator
        });

        switch (rules.operator) {
            case 'equals':
                return fieldValue === rules.value;
            case 'not_equals':
                return fieldValue !== rules.value;
            default:
                return true;
        }
    };

    if (isLoading) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-center py-8">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    if (error) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-red-500 text-center py-8">{error}</div>
                </div>
            </PublicLayout>
        );
    }

    if (requiresAccessCode && !isAccessCodeValid) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="max-w-md mx-auto mt-8">
                    <div className="bg-white p-8 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-6">Accés Protegit</h2>
                        <p className="text-gray-600 mb-6">
                            Aquest formulari requereix un codi d'accés. Si us plau, introdueix-lo per continuar.
                        </p>
                        <form onSubmit={handleAccessCodeSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Codi d'accés
                                </label>
                                <input
                                    type="text"
                                    value={accessCode}
                                    onChange={(e) => setAccessCode(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    placeholder="Introdueix el codi d'accés"
                                    maxLength="6"
                                />
                                {accessCodeError && (
                                    <p className="mt-2 text-sm text-red-600">
                                        {accessCodeError}
                                    </p>
                                )}
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-[#ED5B52] text-white px-4 py-2 rounded-md hover:bg-[#D64A41] transition-colors"
                            >
                                Validar
                            </button>
                        </form>
                    </div>
                </div>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">

                <section className="mb-12">
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-6 bg-gray-100 border-b">
                            <div className="flex justify-between items-center space-y-2">
                                <div>
                                    <p className="text-sm text-gray-600 mb-1">Preu base</p>
                                    <p className="text-1xl font-semibold">{form?.base_price} {form?.currency}</p>
                                </div>
                                
                                    <div className="text-right">
                                        <p className="text-sm text-gray-600 mb-1">Preu final</p>
                                        <p className="text-3xl font-bold text-[#ED5B52]">
                                            {totalPrice} {form?.currency}
                                        </p>
                                    </div>
                                
                            </div>
                        </div>

                        <div className="p-8">
                            <form onSubmit={handleSubmit} className="space-y-8">
                                {form.sections.map((section, sectionIndex) => (
                                    isSectionVisible(section, sectionIndex) && (
                                        <div key={section.id} className="space-y-6">
                                            <h2 className="text-xl font-semibold">{section.name}</h2>
                                            {section.description && (
                                                <p className="text-gray-600 mb-4">{section.description}</p>
                                            )}
                                            
                                            <div className="space-y-4">
                                                {section.fields.map(field => (
                                                    isFieldVisible(field, sectionIndex) && (
                                                        <div key={field.id}>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                {field.label}
                                                                {field.required && <span className="text-red-500 ml-1">*</span>}
                                                            </label>
                                                            {renderField(field, sectionIndex)}
                                                            {formErrors[getFieldId(sectionIndex, field.name)] && (
                                                                <p className="mt-1 text-sm text-red-600">
                                                                    {formErrors[getFieldId(sectionIndex, field.name)]}
                                                                </p>
                                                            )}
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    )
                                ))}

                                <div className="border-t border-gray-200 mt-8 pt-8">
                                    <h2 className="text-xl font-semibold mb-4">Informació de contacte del pagament</h2>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Correu electrònic <span className="text-red-500">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={customerData.email}
                                                onChange={handleCustomerDataChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                required
                                            />
                                            {formErrors.customerEmail && (
                                                <p className="mt-1 text-sm text-red-600">
                                                    {formErrors.customerEmail}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Nom (opcional)
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={customerData.name}
                                                onChange={handleCustomerDataChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="w-full border-2 border-[#ED5B52] bg-[#ED5B52] text-white px-6 py-3 rounded-lg hover:bg-[#D64A41] transition-colors text-center font-semibold"
                                >
                                    Continuar amb el pagament
                                </button>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicPaymentForm; 