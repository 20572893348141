import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaStore, FaBox, FaExternalLinkAlt } from 'react-icons/fa';

const PublicShops = () => {
    const [shops, setShops] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchShops = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops`);
                if (!response.ok) {
                    throw new Error('No s\'han pogut carregar les botigues');
                }
                const data = await response.json();
                setShops(data);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchShops();
    }, []);

    const layoutProps = {
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    if (isLoading) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-center py-8">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    if (error) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-red-500 text-center py-8">{error}</div>
                </div>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">

                <section className="mb-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {shops.map(shop => (
                            <Link 
                                key={shop.id} 
                                to={`/shops/${shop.id}`}
                                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                            >
                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center">
                                            <div className="w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center">
                                                {shop.logo_path ? (
                                                    <img 
                                                        src={`${process.env.REACT_APP_BACKEND_URL}/storage/${shop.logo_path}`}
                                                        alt={shop.name}
                                                        className="w-8 h-8 object-cover rounded-full"
                                                    />
                                                ) : (
                                                    <FaStore className="w-6 h-6 text-orange-600" />
                                                )}
                                            </div>
                                            <h2 className="text-xl font-semibold ml-3">{shop.name}</h2>
                                        </div>
                                        <FaExternalLinkAlt className="text-gray-400" />
                                    </div>
                                    
                                    <p className="text-gray-600 line-clamp-2 mb-4">{shop.description}</p>
                                    
                                    <div className="flex items-center justify-between text-sm">
                                        <div className="flex items-center text-gray-500">
                                            <FaBox className="mr-2" />
                                            <span>
                                                {shop.products_count} {shop.products_count === 1 ? 'producte' : 'productes'} disponibles
                                            </span>
                                        </div>
                                        {shop.status === 'active' && (
                                            <span className="text-orange-600 font-semibold">
                                                Botiga activa
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicShops; 