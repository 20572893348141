import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaFilePdf, FaCreditCard, FaEnvelope, FaCalendar, FaUser, FaEuroSign, FaTrash, FaPaperPlane, FaClock, FaPhone, FaLink, FaExternalLinkAlt, FaLock, FaMoneyBill, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const ViewPayment = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    amount: '',
    frequency: '',
    start_date: '',
    end_date: '',
    until_amount_completed: ''
  });

  useEffect(() => {
    fetchPayment();
  }, [id]);

  useEffect(() => {
    if (payment && isEditing) {
      setEditForm({
        amount: payment.amount || '',
        frequency: payment.configuration?.frequency || '',
        start_date: payment.configuration?.start_date || '',
        end_date: payment.configuration?.end_date || '',
        until_amount_completed: payment.configuration?.until_amount_completed || ''
      });
    }
  }, [payment, isEditing]);

  const fetchPayment = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Error al carregar el cobrament');
      }

      const data = await response.json();
      setPayment(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDocument = (documentPath) => {
    // Extraer solo el nombre del archivo de la ruta completa
    const filename = documentPath.split('/').pop();
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/documents/${filename}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(response => response.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    })
    .catch(error => {
        console.error('Error al obtener el documento:', error);
        alert('Error al obtener el documento');
    });
  };

  const getStatusBadgeClass = (status, isRecurrent) => {
    if (isRecurrent) {
      switch (status) {
        case 'active':
          return 'bg-[#4CAF50] text-white';
        case 'paused':
          return 'bg-[#FFA726] text-white';
        case 'inactive':
          return 'bg-[#EF5350] text-white';
        case 'deleted':
          return 'bg-gray-500 text-white';
        default:
          return 'bg-gray-500 text-white';
      }
    } else {
      switch (status) {
        case 'completed':
          return 'bg-green-500 text-white';
        case 'pending':
          return 'bg-yellow-500 text-white';
        case 'failed':
          return 'bg-red-500 text-white';
        case 'deleted':
          return 'bg-gray-500 text-white';
        default:
          return 'bg-gray-500 text-white';
      }
    }
  };

  const getStatusText = (status, isRecurrent) => {
    if (isRecurrent) {
      switch (status) {
        case 'active':
          return 'Actiu';
        case 'paused':
          return 'Pausat';
        case 'inactive':
          return 'Inactiu';
        case 'deleted':
          return 'Eliminat';
        default:
          return status;
      }
    } else {
      switch (status) {
        case 'completed':
          return 'Completat';
        case 'pending':
          return 'Pendent';
        case 'failed':
          return 'Fallat';
        case 'deleted':
          return 'Eliminat';
        default:
          return status;
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Esto nos llevará a la página anterior
  };

  const getActionTypeText = (actionType) => {
    const types = {
      'view': 'Visualització',
      'payment_attempt': 'Intent de pagament',
      'payment_success': 'Pagament realitzat',
      'payment_error': 'Error en el pagament',
      'payment_cancelled': 'Pagament cancel·lat',
      'status_change': 'Canvi d\'estat',
      'edit': 'Modificació'
    };
    return types[actionType] || actionType;
  };

  const handleDelete = async () => {
    if (window.confirm('Estàs segur que vols eliminar aquest cobrament?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Error al eliminar el cobrament');
        }

        navigate(-1);
      } catch (error) {
        console.error('Error:', error);
        alert('Error al eliminar el cobrament');
      }
    }
  };

  const handleResend = async () => {
    if (window.confirm('Vols reenviar aquest cobrament al destinatari?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}/resend`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Error al reenviar el cobrament');
        }

        alert('Cobrament reenviat correctament');
        // Recargar los datos del pago para actualizar el historial
        fetchPayment();
      } catch (error) {
        console.error('Error:', error);
        alert('Error al reenviar el cobrament');
      }
    }
  };

  const getFrequencyText = (frequency) => {
    const frequencies = {
      'daily': 'Diari',
      'weekly': 'Setmanal',
      'bi-weekly': 'Quinzenal',
      'monthly': 'Mensual',
      'bi-monthly': 'Bimensual',
      'quarterly': 'Trimestral',
      'semi-annually': 'Semestral',
      'annually': 'Anual'
    };
    return frequencies[frequency] || frequency;
  };

  const getDayOfWeek = (date) => {
    const days = [
      'diumenge', 'dilluns', 'dimarts', 'dimecres', 
      'dijous', 'divendres', 'dissabte'
    ];
    return days[new Date(date).getDay()];
  };

  const handlePauseResume = async () => {
    const newStatus = payment.status === 'paused' ? 'active' : 'paused';
    const action = payment.status === 'paused' ? 'reprendre' : 'pausar';
    
    if (window.confirm(`Estàs segur que vols ${action} aquest cobrament recurrent?`)) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}/toggle-status`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status: newStatus })
        });

        if (!response.ok) throw new Error(`Error al ${action} el cobrament`);
        
        fetchPayment(); // Recarregar les dades
        alert(`Cobrament ${action}t correctament`);
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    
    // Validar que hi ha almenys un tipus de finalització
    if (!editForm.end_date && !editForm.until_amount_completed) {
      alert('Cal especificar una data de finalització o un import total');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: editForm.amount,
          configuration: {
            frequency: editForm.frequency,
            start_date: editForm.start_date,
            end_date: editForm.end_date || null,
            until_amount_completed: editForm.until_amount_completed || null,
            recipients: payment.configuration.recipients // Mantenim els destinataris existents
          }
        })
      });

      if (!response.ok) throw new Error('Error al modificar el cobrament');
      
      fetchPayment();
      setIsEditing(false);
      alert('Cobrament modificat correctament');
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    }
  };

  // Render dels botons d'acció per pagaments recurrents
  const renderRecurringActions = () => {
    if (payment.payment_type_id !== 2 || payment.status === 'deleted') return null;

    return (
      <div className="flex space-x-2">
        <button
          onClick={handlePauseResume}
          className={`px-4 py-2 rounded-md text-white ${
            payment.status === 'paused' 
              ? 'bg-green-500 hover:bg-green-600' 
              : 'bg-yellow-500 hover:bg-yellow-600'
          }`}
        >
          {payment.status === 'paused' ? 'Reprendre' : 'Pausar'}
        </button>
        <button
          onClick={() => setIsEditing(true)}
          className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600"
        >
          Modificar
        </button>
      </div>
    );
  };

  // Formulari d'edició
  const renderEditForm = () => {
    if (!isEditing) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-lg font-semibold mb-4">Modificar cobrament recurrent</h3>
          <form onSubmit={handleEdit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Import per quota</label>
              <input
                type="number"
                step="0.01"
                value={editForm.amount}
                onChange={(e) => setEditForm({...editForm, amount: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Freqüència</label>
              <select
                value={editForm.frequency}
                onChange={(e) => setEditForm({...editForm, frequency: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
              >
                <option value="daily">Diari</option>
                <option value="weekly">Setmanal</option>
                <option value="bi-weekly">Quinzenal</option>
                <option value="monthly">Mensual</option>
                <option value="bi-monthly">Bimensual</option>
                <option value="quarterly">Trimestral</option>
                <option value="semi-annually">Semestral</option>
                <option value="annually">Anual</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Data d'inici</label>
              <input
                type="date"
                value={editForm.start_date}
                onChange={(e) => setEditForm({...editForm, start_date: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
              />
            </div>
            
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Tipus de finalització</label>
              <div className="space-y-4">
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="endType"
                      checked={editForm.end_date !== ''}
                      onChange={() => {
                        const today = new Date().toISOString().split('T')[0];
                        setEditForm({
                          ...editForm,
                          end_date: today,
                          until_amount_completed: ''
                        });
                      }}
                      className="form-radio text-[#ED5B52]"
                    />
                    <span className="ml-2">Per data</span>
                  </label>
                  {editForm.end_date !== '' && (
                    <input
                      type="date"
                      value={editForm.end_date}
                      onChange={(e) => setEditForm({...editForm, end_date: e.target.value})}
                      className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                    />
                  )}
                </div>
                
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="endType"
                      checked={editForm.until_amount_completed !== ''}
                      onChange={() => {
                        setEditForm({
                          ...editForm,
                          end_date: '',
                          until_amount_completed: payment.amount || '0'
                        });
                      }}
                      className="form-radio text-[#ED5B52]"
                    />
                    <span className="ml-2">Per import total</span>
                  </label>
                  {editForm.until_amount_completed !== '' && (
                    <input
                      type="number"
                      step="0.01"
                      value={editForm.until_amount_completed}
                      onChange={(e) => setEditForm({...editForm, until_amount_completed: e.target.value})}
                      className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                      placeholder="Import total a cobrar"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel·lar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64B42]"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  // Afegir funció per calcular el número de quotes
  const calculateInstallments = (startDate, endDate, frequency) => {
    if (!startDate || !endDate) return 'Il·limitat';
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
    
    switch (frequency) {
      case 'monthly': return months;
      case 'bi-monthly': return Math.ceil(months / 2);
      case 'quarterly': return Math.ceil(months / 3);
      case 'semi-annually': return Math.ceil(months / 6);
      case 'annually': return Math.ceil(months / 12);
      default: return 'N/A';
    }
  };

  // Funció per copiar l'enllaç
  const copyPaymentLink = () => {
    const paymentUrl = `${window.location.origin}/public-payments/${payment.public_hash}`;
    navigator.clipboard.writeText(paymentUrl);
    alert('Enllaç copiat al portapapers');
  };

  // Afegim la funció de càlcul (després de getFrequencyText i getDayOfWeek)
  const calculatePaymentDetails = () => {
    if (!payment || payment.payment_type?.slug !== 'cobrament-recurrent') return null;

    const startDate = new Date(payment.configuration.start_date);
    const amount = parseFloat(payment.amount);
    
    // Cas 1: Tenim data de finalització
    if (payment.configuration.end_date) {
      const endDate = new Date(payment.configuration.end_date);
      let numberOfPayments;
      
      switch(payment.configuration.frequency) {
        case 'daily': 
          numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); 
          break;
        case 'weekly': 
          numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 7)); 
          break;
        case 'bi-weekly': 
          numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 14)); 
          break;
        case 'monthly': 
          const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                        (endDate.getMonth() - startDate.getMonth()) + 1;
          numberOfPayments = months;
          break;
        case 'bi-monthly': 
          numberOfPayments = Math.ceil(((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.44) + 1) / 2); 
          break;
        case 'quarterly': 
          numberOfPayments = Math.ceil(((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.44) + 1) / 3); 
          break;
        case 'semi-annually': 
          numberOfPayments = Math.ceil(((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.44) + 1) / 6); 
          break;
        case 'annually': 
          numberOfPayments = Math.ceil(((endDate - startDate) / (1000 * 60 * 60 * 24 * 365.25) + 1)); 
          break;
        default: 
          numberOfPayments = 0;
      }
      
      return {
        numberOfPayments,
        totalAmount: (amount * numberOfPayments).toFixed(2)
      };
    }
    // Cas 2: Tenim import total objectiu
    else if (payment.configuration.until_amount_completed) {
      const totalAmount = parseFloat(payment.configuration.until_amount_completed);
      return {
        numberOfPayments: Math.ceil(totalAmount / amount),
        totalAmount: totalAmount.toFixed(2)
      };
    }
    
    return null;
  };

  // Función para obtener el icono según el estado
  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <FaCheckCircle className="text-green-500" />;
      case 'failed':
        return <FaTimesCircle className="text-red-500" />;
      case 'pending':
        return <FaClock className="text-yellow-500" />;
      default:
        return null;
    }
  };

  // Función para formatear el estado en catalán
  const formatStatus = (status) => {
    const statusMap = {
      'completed': 'Completat',
      'failed': 'Fallat',
      'pending': 'Pendent'
    };
    return statusMap[status] || status;
  };

  // Afegim la funció per realitzar cobraments manuals
  const handleChargeNow = async () => {
    if (!window.confirm('Vols realitzar un cobrament ara?')) {
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${payment.public_hash}/charge-now`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (!response.ok) {
            throw new Error('Error al realitzar el cobrament');
        }

        alert('Cobrament realitzat correctament');
        fetchPayment(); // Recarreguem les dades
    } catch (error) {
        console.error('Error:', error);
        alert(error.message);
    }
  };

  // Añadir función para manejar la eliminación de la tarjeta
  const handleDeleteCard = async () => {
    if (window.confirm('Estàs segur que vols eliminar la targeta registrada? Això aturarà els cobraments recurrents.')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${id}/remove-card`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Error al eliminar la targeta');
        }

        // Recargar los datos del pago para actualizar la información
        fetchPayment();
        alert('Targeta eliminada correctament');
      } catch (error) {
        console.error('Error:', error);
        alert('Error al eliminar la targeta');
      }
    }
  };

  // Afegim el component per mostrar la informació de pagament recurrent
  const renderRecurringPaymentInfo = () => {
    if (payment.payment_type?.slug !== 'cobrament-recurrent') return null;

    return (
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FaCreditCard className="mr-2 text-gray-500" />
          Informació de pagament recurrent
        </h2>
        <div className="space-y-3">
          {payment.merchant_identifier ? (
            <>
              <p className="flex items-center text-gray-600">
                <span className="font-medium mr-2">Targeta registrada:</span>
                <span className="px-2 py-1 bg-green-100 text-green-800 rounded">
                  Sí
                </span>
              </p>
              <p className="flex items-center text-gray-600">
                <span className="font-medium mr-2">Identificador:</span>
                <code className="bg-gray-100 px-2 py-1 rounded">
                  {payment.merchant_identifier}
                </code>
              </p>
              <div className="flex space-x-2 mt-4">
                {payment.status === 'active' && (
                  <button
                    onClick={handleChargeNow}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                  >
                    Realitzar cobrament ara
                  </button>
                )}
                <button
                  onClick={handleDeleteCard}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors flex items-center"
                >
                  <FaTrash className="mr-2" />
                  Eliminar targeta
                </button>
              </div>
            </>
          ) : (
            <p className="text-yellow-600">
              Encara no s'ha registrat cap targeta
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        {isLoading ? (
          <LoadingSpinner message="Carregant detalls del pagament..." />
        ) : error ? (
          <div className="bg-white rounded-lg shadow p-8">
            <div className="text-center">
              <div className="text-red-500 text-xl font-semibold mb-2">
                Error al carregar el pagament
              </div>
              <p className="text-gray-600">{error}</p>
            </div>
          </div>
        ) : payment ? (
          <>
            {/* Cabecera */}
            <div className="flex justify-between items-center mb-6">
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  Detalls del Cobrament
                </h1>
                {payment.deleted_at && (
                  <span className="mt-2 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
                    Cobrament Eliminat
                  </span>
                )}
              </div>
              <div className="flex space-x-3">
                {!payment.deleted_at && payment.status !== 'completed' && (
                  <>
                    <button
                      onClick={handleResend}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 flex items-center"
                      title="Reenviar cobrament"
                    >
                      <FaPaperPlane className="mr-2" /> Reenviar
                    </button>
                    <button
                      onClick={handleDelete}
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200 flex items-center"
                      title="Eliminar cobrament"
                    >
                      <FaTrash className="mr-2" /> Eliminar
                    </button>
                  </>
                )}
                {renderRecurringActions()}
                <button
                  onClick={handleGoBack}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                >
                  <FaArrowLeft className="mr-2" /> Tornar
                </button>
              </div>
            </div>

            {/* Contenido principal */}
            <div className="bg-white shadow rounded-lg overflow-hidden">
              {/* Información básica */}
              <div className="p-6 border-b border-gray-200">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Informació general */}
                  <div>
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                      <FaCreditCard className="mr-2 text-gray-500" />
                      Informació general
                    </h2>
                    <div className="space-y-3">
                      <p className="flex items-center text-gray-600">
                        <span className="font-medium mr-2">Nom:</span>
                        {payment.name}
                      </p>
                      <p className="flex items-center text-gray-600">
                        <span className="font-medium mr-2">Tipus:</span>
                        {payment.payment_type?.name}
                      </p>
                      <p className="flex items-center text-gray-600">
                        <span className="font-medium mr-2">Passarel·la:</span>
                        {payment.payment_gateway?.name}
                      </p>
                      <p className="flex items-center text-gray-600">
                        <FaEuroSign className="mr-1" />
                        <span className="font-medium mr-2">Import per quota:</span>
                        {payment.amount}€
                      </p>
                      {payment.payment_type?.slug === 'cobrament-recurrent' && (
                        <>
                          <p className="flex items-center text-gray-600">
                            <FaClock className="mr-2" />
                            <span className="font-medium mr-2">Freqüència:</span>
                            {getFrequencyText(payment.configuration?.frequency)}
                          </p>
                          {(() => {
                            const details = calculatePaymentDetails();
                            if (details) {
                              return (
                                <>
                                  <p className="flex items-center text-gray-600">
                                    <FaEuroSign className="mr-1" />
                                    <span className="font-medium mr-2">Import total a cobrar:</span>
                                    {details.totalAmount}€
                                  </p>
                                  <p className="flex items-center text-gray-600">
                                    <FaClock className="mr-2" />
                                    <span className="font-medium mr-2">Nombre total de pagaments:</span>
                                    {details.numberOfPayments}
                                  </p>
                                </>
                              );
                            }
                            return null;
                          })()}
                        </>
                      )}
                      <p className="flex items-center">
                        <span className="font-medium mr-2">Estat:</span>
                        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusBadgeClass(payment.status, payment.payment_type?.slug === 'cobrament-recurrent')}`}>
                          {getStatusText(payment.status, payment.payment_type?.slug === 'cobrament-recurrent')}
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Dates del cobrament */}
                  <div>
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                      <FaCalendar className="mr-2 text-gray-500" />
                      Dates del cobrament
                    </h2>
                    <div className="space-y-3">
                      <p className="flex items-center text-gray-600">
                        <span className="font-medium mr-2">Data de creació:</span>
                        {new Date(payment.created_at).toLocaleDateString('ca-ES')}
                      </p>
                      <p className="flex items-center text-gray-600">
                        <span className="font-medium mr-2">Data d'inici:</span>
                        {new Date(payment.configuration.start_date).toLocaleDateString('ca-ES')}
                      </p>
                      {payment.payment_type?.slug === 'cobrament-recurrent' ? (
                        <>
                          {payment.last_billed_at && (
                            <p className="flex items-center text-gray-600">
                              <span className="font-medium mr-2">Últim cobrament:</span>
                              {new Date(payment.last_billed_at).toLocaleDateString('ca-ES')}
                            </p>
                          )}
                          {payment.next_billing_date && (
                            <p className="flex items-center text-gray-600">
                              <span className="font-medium mr-2">Pròxim cobrament:</span>
                              {new Date(payment.next_billing_date).toLocaleDateString('ca-ES')}
                              <span className="ml-2 text-gray-500">
                                ({getDayOfWeek(payment.next_billing_date)})
                              </span>
                            </p>
                          )}
                          {payment.configuration.end_date ? (
                            <p className="flex items-center text-gray-600">
                              <span className="font-medium mr-2">Data de finalització:</span>
                              {new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')}
                            </p>
                          ) : payment.configuration.until_amount_completed ? (
                            <p className="flex items-center text-gray-600">
                              <span className="font-medium mr-2">Finalització:</span>
                              <span>Quan s'arribi a {payment.configuration.until_amount_completed}€</span>
                            </p>
                          ) : (
                            <p className="flex items-center text-gray-600">
                              <span className="font-medium mr-2">Finalització:</span>
                              <span className="text-gray-500">Sense data de finalització</span>
                            </p>
                          )}
                        </>
                      ) : (
                        <p className="flex items-center text-gray-600">
                          <span className="font-medium mr-2">Data de finalització:</span>
                          {new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Informació dels destinataris */}
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold mb-4 flex items-center">
                  <FaUser className="mr-2 text-gray-500" />
                  Informació del destinatari
                </h2>
                <div className="space-y-3">
                  {payment.customer && (
                    <>
                      {payment.customer.name && (
                        <p className="flex items-center text-gray-600">
                          <FaUser className="mr-2" />
                          <span className="font-medium mr-2">Nom:</span>
                          {payment.customer.name}
                        </p>
                      )}
                      <p className="flex items-center text-gray-600">
                        <FaEnvelope className="mr-2" />
                        <span className="font-medium mr-2">Email:</span>
                        {payment.customer.email}
                      </p>
                      {payment.customer.phone && (
                        <p className="flex items-center text-gray-600">
                          <FaPhone className="mr-2" />
                          <span className="font-medium mr-2">Telèfon:</span>
                          {payment.customer.phone}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Informació de pagament recurrent */}
              {renderRecurringPaymentInfo()}

              {/* Documentación adjunta */}
              {payment.configuration?.document_path && (
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-4 flex items-center">
                    <FaFilePdf className="mr-2 text-gray-500" />
                    Document adjunt
                  </h2>
                  <button
                    onClick={() => viewDocument(payment.configuration.document_path)}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <FaFilePdf className="mr-2" />
                    Veure Document
                  </button>
                </div>
              )}

              {/* Enllaç de pagament */}
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold mb-4 flex items-center">
                  <FaLink className="mr-2 text-gray-500" />
                  Enllaç de pagament
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg">
                    <input
                      type="text"
                      value={`${window.location.origin}/public-payments/${payment.public_hash}`}
                      className="flex-1 bg-transparent border-none focus:ring-0"
                      readOnly
                    />
                    <button
                      onClick={copyPaymentLink}
                      className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                      title="Copiar enllaç de pagament"
                    >
                      <FaLink className="mr-2" /> Copiar
                    </button>
                    <a
                      href={`/public-payments/${payment.public_hash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                      title="Veure pàgina de pagament"
                    >
                      <FaExternalLinkAlt className="mr-2" /> Veure
                    </a>
                  </div>
                  {Boolean(payment.requires_access_code) && (
                    <div className="text-sm text-gray-600 flex items-center">
                      <FaLock className="mr-2" />
                      Aquest pagament requereix codi d'accés
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Después del div del enlace de pago y antes del historial de actividad */}
            {payment?.transactions?.length > 0 && (
              <div className="mt-6 bg-white shadow rounded-lg overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-4 flex items-center">
                    <FaMoneyBill className="mr-2 text-gray-500" />
                    Historial de Transaccions
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Referència
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Import
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Estat
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Codi Autorització
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {payment.transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {new Date(transaction.created_at).toLocaleString('ca-ES')}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {transaction.reference}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {transaction.amount}€
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex items-center space-x-2">
                                {getStatusIcon(transaction.status)}
                                <span>{formatStatus(transaction.status)}</span>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {transaction.authorization_code || '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* Historial de actividad */}
            {payment?.logs?.length > 0 ? (
                <div className="mt-6 bg-white shadow rounded-lg overflow-hidden">
                    <div className="p-6">
                        <h2 className="text-xl font-semibold mb-4">Historial d'Activitat</h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Data
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Acció
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Detalls
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {payment.logs.map((log) => (
                                        <tr key={log.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {new Date(log.created_at).toLocaleString('ca-ES')}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                                {getActionTypeText(log.action_type)}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500">
                                                {log.details || '-'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-6 bg-white shadow rounded-lg overflow-hidden">
                    <div className="p-6">
                        <h2 className="text-xl font-semibold mb-4">Historial d'Activitat</h2>
                        <p className="text-gray-500 text-center">No hi ha registres d'activitat</p>
                    </div>
                </div>
            )}
          </>
        ) : (
          <div className="bg-white rounded-lg shadow p-8">
            <div className="text-center">
              <div className="text-gray-500 text-xl font-semibold">
                No s'ha trobat el pagament
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer />
      {renderEditForm()}
    </div>
  );
};

export default ViewPayment;
