import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import PaymentTypeImage from './PaymentTypeImage';

const PublicOpenPayment = () => {
    const { hash } = useParams();
    const [payment, setPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Estats per als camps personalitzables
    const [customAmount, setCustomAmount] = useState('');
    const [customConcept, setCustomConcept] = useState('');
    const [recipient, setRecipient] = useState({ email: '', name: '' });
    const [formErrors, setFormErrors] = useState({});

    const [requiresAccessCode, setRequiresAccessCode] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
    const [accessCodeError, setAccessCodeError] = useState('');

    useEffect(() => {
        const fetchPayment = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-open-payments/${hash}`);
                if (!response.ok) {
                    throw new Error('No s\'ha pogut carregar el pagament obert');
                }
                const data = await response.json();
                
                if (data.requires_access_code) {
                    setRequiresAccessCode(true);
                    setPayment(null);
                } else {
                    setPayment(data);
                    if (data.concept) setCustomConcept(data.concept);
                    if (data.amount && !data.configuration?.allow_custom_amount) {
                        setCustomAmount(data.amount);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPayment();
    }, [hash]);

    const handleAccessCodeSubmit = async (e) => {
        e.preventDefault();
        setAccessCodeError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-open-payments/${hash}/verify-access-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ access_code: accessCode })
            });

            const data = await response.json();

            if (response.ok) {
                setIsAccessCodeValid(true);
                setPayment(data);
                if (data.concept) setCustomConcept(data.concept);
                if (data.amount && !data.configuration?.allow_custom_amount) {
                    setCustomAmount(data.amount);
                }
            } else {
                setAccessCodeError(data.error || 'Codi d\'accés incorrecte');
            }
        } catch (error) {
            setAccessCodeError('Error en validar el codi d\'accés');
        }
    };

    const renderAccessCodeForm = () => {
        return (
            <div className="max-w-md mx-auto mt-8">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-6">Accés Protegit</h2>
                    <p className="text-gray-600 mb-6">
                        Aquest pagament requereix un codi d'accés. Si us plau, introdueix-lo per continuar.
                    </p>
                    <form onSubmit={handleAccessCodeSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Codi d'accés
                            </label>
                            <input
                                type="text"
                                value={accessCode}
                                onChange={(e) => setAccessCode(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                placeholder="Introdueix el codi d'accés"
                                maxLength="6"
                            />
                            {accessCodeError && (
                                <p className="mt-2 text-sm text-red-600">
                                    {accessCodeError}
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#ED5B52] text-white px-4 py-2 rounded-md hover:bg-[#D64A41] transition-colors"
                        >
                            Validar
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    // Validació del formulari
    const validateForm = () => {
        const errors = {};
        
        if (payment.configuration?.allow_custom_amount) {
            if (!customAmount || customAmount <= 0) {
                errors.amount = "L'import és obligatori i ha de ser major que 0";
            }
        }

        if (payment.configuration?.allow_custom_concept && !customConcept) {
            errors.concept = "El concepte és obligatori";
        }

        if (!recipient.email) {
            errors.email = "El correu electrònic és obligatori";
        } else if (!/\S+@\S+\.\S+/.test(recipient.email)) {
            errors.email = "El correu electrònic no és vàlid";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Gestió del submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        const paymentData = {
            amount: payment.configuration?.allow_custom_amount ? customAmount : payment.amount,
            concept: payment.configuration?.allow_custom_concept ? customConcept : payment.concept,
            recipient: recipient,
            open_payment_hash: hash
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/process-open-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData)
            });

            if (!response.ok) {
                throw new Error('Error al processar el pagament');
            }

            const data = await response.json();
            window.location.href = data.payment_url;
        } catch (error) {
            setError(error.message);
        }
    };

    // Renderitzar el formulari de personalització
    const renderCustomizationForm = () => {
        return (
            <form onSubmit={handlePaymentSubmit} className="space-y-6">
                {/* Import personalitzable */}
                {payment.configuration?.allow_custom_amount && (
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Import (€)
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            min="0"
                            value={customAmount}
                            onChange={(e) => setCustomAmount(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="Introdueix l'import"
                        />
                        {formErrors.amount && (
                            <p className="mt-1 text-sm text-red-600">{formErrors.amount}</p>
                        )}
                    </div>
                )}

                {/* Concepte personalitzable */}
                {payment.configuration?.allow_custom_concept && (
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Concepte
                        </label>
                        <input
                            type="text"
                            value={customConcept}
                            onChange={(e) => setCustomConcept(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="Introdueix el concepte"
                        />
                        {formErrors.concept && (
                            <p className="mt-1 text-sm text-red-600">{formErrors.concept}</p>
                        )}
                    </div>
                )}

                {/* Destinatari */}
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Correu electrònic
                        </label>
                        <input
                            type="email"
                            value={recipient.email}
                            onChange={(e) => setRecipient({...recipient, email: e.target.value})}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="El teu correu electrònic"
                        />
                        {formErrors.email && (
                            <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Nom (opcional)
                        </label>
                        <input
                            type="text"
                            value={recipient.name}
                            onChange={(e) => setRecipient({...recipient, name: e.target.value})}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="El teu nom"
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full bg-[#ED5B52] text-white py-3 px-6 rounded-lg hover:bg-[#D64A41] transition-colors"
                >
                    Procesar Pago
                </button>

                {formErrors.submit && (
                    <p className="text-red-500 text-sm">{formErrors.submit}</p>
                )}
            </form>
        );
    };

    const getFrequencyText = (frequency) => {
        const frequencies = {
            'daily': 'Diari',
            'weekly': 'Setmanal',
            'bi-weekly': 'Quinzenal',
            'monthly': 'Mensual',
            'bi-monthly': 'Bimestral',
            'quarterly': 'Trimestral',
            'semi-annually': 'Semestral',
            'annually': 'Anual'
        };
        return frequencies[frequency] || frequency;
    };

    const renderRecurringInfo = () => {
        if (payment.payment_type.slug !== 'cobrament-recurrent') return null;

        return (
            <div className="mb-6">
                <h3 className="font-semibold mb-2">Detalls del pagament recurrent</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                    <div className="space-y-2">
                        <div className="flex items-center text-gray-600">
                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <span>Freqüència: {getFrequencyText(payment.configuration?.frequency)}</span>
                        </div>
                        {payment.configuration?.start_date && (
                            <div className="flex items-center text-gray-600">
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span>Data d'inici: {new Date(payment.configuration.start_date).toLocaleDateString('ca-ES')}</span>
                            </div>
                        )}
                        {payment.configuration?.end_date && (
                            <div className="flex items-center text-gray-600">
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span>Data de finalització: {new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderDocuments = () => {
        if (!payment.documents || payment.documents.length === 0) return null;

        return (
            <div className="mb-6">
                <h3 className="font-semibold mb-2">Documents adjunts</h3>
                <div className="space-y-2">
                    {payment.documents.map((doc, index) => (
                        <div key={index} className="bg-gray-50 rounded-lg p-4">
                            <a 
                                href={`${process.env.REACT_APP_BACKEND_URL}/storage/${doc.file_path}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center text-[#ED5B52] hover:text-[#D64A41] transition-colors"
                            >
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                {doc.file_name}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const layoutProps = {
        shop: {
            id: payment?.shop_id,
            name: payment?.shop_name || "La meva botiga",
            description: payment?.shop_description || "Benvingut a la nostra botiga online",
            address: payment?.shop_address,
            email: payment?.shop_email,
            phone: payment?.shop_phone
        },
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({});

        try {
            const paymentData = {
                amount: payment.configuration?.allow_custom_amount ? customAmount : payment.amount,
                concept: payment.configuration?.allow_custom_concept ? customConcept : payment.concept,
                recipient: recipient
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-open-payments/${hash}/process-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error al procesar el pago');
            }

            // Si la respuesta incluye un formulario de redirección
            if (data.success && data.data.method === 'POST') {
                // Crear un formulario oculto
                const form = document.createElement('form');
                form.method = data.data.method;
                form.action = data.data.url;
                form.style.display = 'none'; // Ocultar el formulario

                // Agregar los campos necesarios
                Object.entries(data.data.fields).forEach(([key, value]) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                });

                // Agregar el formulario al DOM y enviarlo
                document.body.appendChild(form);
                setTimeout(() => {
                    form.submit();
                }, 100);
            }

        } catch (error) {
            console.error('Error:', error);
            setFormErrors({ submit: error.message });
        }
    };

    if (isLoading) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-center py-8">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    if (error) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-red-500 text-center py-8">{error}</div>
                </div>
            </PublicLayout>
        );
    }

    if (requiresAccessCode && !isAccessCodeValid) {
        return (
            <PublicLayout {...layoutProps}>
                {renderAccessCodeForm()}
            </PublicLayout>
        );
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">

                <section className="mb-12">
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div>
                                    {/* Informació bàsica del pagament */}
                                    <h2 className="text-2xl font-semibold mb-4">{payment.name}</h2>
                                    <p className="text-gray-600 mb-6">{payment.description}</p>
                                    
                                    {/* Tipus de pagament i import fix si no és personalitzable */}
                                    <div className="mb-6">
                                        <h3 className="font-semibold mb-2">Tipus de Pagament</h3>
                                        <p className="text-gray-600">{payment.payment_type.name}</p>
                                    </div>

                                    {!payment.configuration?.allow_custom_amount && (
                                        <div className="mb-6">
                                            <h3 className="font-semibold mb-2">Import</h3>
                                            <p className="text-2xl font-bold text-[#ED5B52]">{payment.amount}€</p>
                                        </div>
                                    )}

                                    {renderRecurringInfo()}
                                    {renderDocuments()}
                                </div>

                                <div>
                                    {/* Formulari de personalització */}
                                    {renderCustomizationForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicOpenPayment; 