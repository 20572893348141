import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaSearch, FaRegCreditCard, FaRegCalendarAlt, FaRegMoneyBillAlt, FaShoppingBag, FaClipboardList } from 'react-icons/fa';
import withPublicStyles from './hoc/withPublicStyles';
import { useStyles } from '../../contexts/StyleContext';
import LoadingSpinner from '../common/LoadingSpinner';

// Añadir estos estilos globalmente usando CSS-in-JS
const scrollbarHideStyles = {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    WebkitOverflowScrolling: 'touch',
    WebkitScrollbar: 'none'
};

// Definim les imatges per defecte segons el tipus de pagament
const DEFAULT_PAYMENT_IMAGES = {
    'cobrament-recurrent': '/images/payments/recurring-payment.jpg',
    'cobrament-unic': '/images/payments/single-payment.jpg',
    'cobrament-obert': '/images/payments/open-payment.jpg',
    default: '/images/payments/default-payment.jpg'
};

// Component per la imatge del pagament
const PaymentTypeImage = ({ paymentType, amount, allowCustomAmount }) => {
    // Funció per obtenir la configuració segons el tipus
    const getTypeConfig = (type) => {
        const configs = {
            'cobrament-recurrent': {
                icon: <FaRegCalendarAlt className="w-10 h-10" />,
                label: 'Pagament Recurrent',
                bgColor: 'from-blue-50 to-blue-100',
                iconColor: 'text-blue-500'
            },
            'cobrament-unic': {
                icon: <FaRegCreditCard className="w-10 h-10" />,
                label: 'Pagament Únic',
                bgColor: 'from-green-50 to-green-100',
                iconColor: 'text-green-500'
            },
            'cobrament-obert': {
                icon: <FaRegMoneyBillAlt className="w-10 h-10" />,
                label: 'Pagament Obert',
                bgColor: 'from-purple-50 to-purple-100',
                iconColor: 'text-purple-500'
            },
            default: {
                icon: <FaRegCreditCard className="w-10 h-10" />,
                label: 'Pagament',
                bgColor: 'from-gray-50 to-gray-100',
                iconColor: 'text-gray-500'
            }
        };
        return configs[type] || configs.default;
    };

    const config = getTypeConfig(paymentType);

    return (
        <div className={`w-full h-full rounded-lg bg-gradient-to-br ${config.bgColor} flex flex-col items-center justify-center p-4`}>
            <div className={`mb-3 ${config.iconColor}`}>
                {config.icon}
            </div>
            <h5 className="text-sm font-medium text-gray-700 mb-2">
                {config.label}
            </h5>
            <p className="text-sm text-gray-600">
                {allowCustomAmount ? (
                    "Import personalitzable"
                ) : (
                    amount ? `${amount}€` : "Sense import"
                )}
            </p>
        </div>
    );
};

// Component per mostrar imatge per defecte
const DefaultImage = ({ type = 'product', seed }) => {
    // Configuracions de colors (fons i icona)
    const colorConfigs = [
        { bg: 'from-blue-50 to-blue-100', icon: 'text-blue-500' },
        { bg: 'from-green-50 to-green-100', icon: 'text-green-500' },
        { bg: 'from-purple-50 to-purple-100', icon: 'text-purple-500' },
        { bg: 'from-pink-50 to-pink-100', icon: 'text-pink-500' },
        { bg: 'from-yellow-50 to-yellow-100', icon: 'text-yellow-500' },
        { bg: 'from-indigo-50 to-indigo-100', icon: 'text-indigo-500' }
    ];

    // Seleccionar configuració basada en el seed
    const colorIndex = seed ? Math.abs(seed.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % colorConfigs.length : 0;
    const { bg, icon } = colorConfigs[colorIndex];

    return (
        <div className={`w-full h-full rounded-lg bg-gradient-to-br ${bg} flex items-center justify-center`}>
            {type === 'product' ? (
                <FaShoppingBag className={`w-12 h-12 ${icon}`} />
            ) : (
                <FaClipboardList className={`w-12 h-12 ${icon}`} />
            )}
        </div>
    );
};

// Definimos los estilos por defecto que usamos en otras pantallas públicas
const DEFAULT_STYLES = {
    primary_color: '#ED5B52',
    secondary_color: '#D64A41',
    accent_color: '#ED5B52',
    text_color: '#111827',
    heading_color: '#1F2937',
    background_color: '#FFFFFF',
    secondary_background: '#F3F4F6',
    heading_font: 'Inter',
    body_font: 'Inter',
    button_styles: {
        primary: {
            background: '#ED5B52',
            text: '#FFFFFF',
            hover: '#D64A41'
        },
        secondary: {
            background: '#FFFFFF',
            text: '#ED5B52',
            border: '#ED5B52'
        }
    },
    card_styles: {
        background: '#FFFFFF',
        border: '#E5E7EB',
        shadow: 'sm'
    }
};

const PublicPlace = () => {
    const { style, loading } = useStyles();
    const navigate = useNavigate();
    
    // 1. Todos los useState juntos al inicio
    const [shop, setShop] = useState(null);
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [products, setProducts] = useState([]);
    const [payments, setPayments] = useState([]);
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPayments, setIsLoadingPayments] = useState(false);
    const [error, setError] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isLoadingForms, setIsLoadingForms] = useState(false);
    const [formsError, setFormsError] = useState(null);
    const [profile, setProfile] = useState(null);

    // 2. Todos los useEffect juntos después de los useState
    useEffect(() => {
        fetchShopData();
        fetchShops();
    }, []);

    useEffect(() => {
        if (selectedShop) {
            fetchShopProducts(selectedShop.id);
        }
    }, [selectedShop]);

    useEffect(() => {
        fetchForms();
    }, []);

    useEffect(() => {
        fetchProfile();
    }, []);

    useEffect(() => {
        const fetchPayments = async () => {
            setIsLoadingPayments(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-open-payments`);
                if (!response.ok) {
                    throw new Error('Error al cargar los pagaments');
                }
                const data = await response.json();
                const transformedPayments = transformPaymentData(data);
                setPayments(transformedPayments);
            } catch (error) {
                console.error('Error:', error);
                setPaymentError(error.message);
            } finally {
                setIsLoadingPayments(false);
            }
        };
        fetchPayments();
    }, []);

    // 3. Funciones auxiliares después de los hooks
    const fetchShopData = async () => {
        try { 
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops`);
            if (!response.ok) {
                throw new Error('No s\'ha pogut carregar la botiga');
            }
            const data = await response.json();
            if (data && data.length > 0) {
                setShop(data[0]); // Usar la primera tienda
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching shop:', error);
            setError(error.message);
            setIsLoading(false);
        }
    };

    const fetchShops = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops`);
            if (!response.ok) {
                throw new Error('No s\'han pogut carregar les botigues');
            }
            const data = await response.json();
            const transformedShops = data.map(shop => ({
                id: shop.id,
                name: shop.name,
                description: shop.description,
                image: shop.logo_path 
                    ? `${process.env.REACT_APP_BACKEND_URL}/api/storage/${shop.logo_path}`
                    : "https://via.placeholder.com/300x200"
            }));
            setShops(transformedShops);
            if (!selectedShop && transformedShops.length > 0) {
                setSelectedShop(transformedShops[0]);
            }
        } catch (error) {
            console.error('Error fetching shops:', error);
            setError(error.message);
        }
    };

    const fetchShopProducts = async (shopId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${shopId}/products`);
            if (!response.ok) {
                throw new Error('No s\'han pogut carregar els productes');
            }
            const data = await response.json();
            const transformedProducts = data.map(product => ({
                id: product.id,
                name: product.name,
                description: product.description,
                price: product.pivot?.price || product.price,
                // Agafar la primera imatge de l'array si existeix
                image: product.images && product.images.length > 0 ? product.images[0] : null
            }));
            setProducts(transformedProducts);
        } catch (error) {
            console.error('Error fetching products:', error);
            setError(error.message);
        }
    };

    const fetchForms = async () => {
        setIsLoadingForms(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/paymentforms`);
            if (!response.ok) {
                throw new Error('No s\'han pogut carregar els formularis');
            }
            const data = await response.json();
            const transformedForms = data.map(form => ({
                id: form.id,
                name: form.name,
                description: form.description || 'Sense descripció',
                price: form.base_price || 0,
                image: form.image_path 
                    ? `${process.env.REACT_APP_BACKEND_URL}/api/storage/${form.image_path}`
                    : "https://via.placeholder.com/300x200",
                hash: form.hash
            }));
            setForms(transformedForms);
        } catch (error) {
            console.error('Error loading forms:', error);
            setFormsError(error.message);
        } finally {
            setIsLoadingForms(false);
        }
    };

    const handleShopSelect = async (shop) => {
        setSelectedShop(shop);
        setCurrentSlide(0);
    };

    const getPaymentImage = (payment) => {
        if (!payment.payment_type) return DEFAULT_PAYMENT_IMAGES.default;
        return DEFAULT_PAYMENT_IMAGES[payment.payment_type] || DEFAULT_PAYMENT_IMAGES.default;
    };

    const transformPaymentData = (backendPayments) => {
        return backendPayments.map(payment => ({
            id: payment.id,
            name: payment.name || payment.concept,
            description: payment.description || 'Sense descripció',
            price: payment.amount,
            payment_type: payment.payment_type?.slug || 'default',
            configuration: payment.configuration,
            public_hash: payment.public_hash,
            payment_gateway: payment.payment_gateway
        }));
    };

    const fetchProfile = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profile/public`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('No s\'ha pogut carregar el perfil');
            }
            const data = await response.json();
            setProfile(data);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    // 4. Renderizado condicional basado en loading
    if (loading) {
        return <LoadingSpinner />;
    }

    // 5. Renderizado principal
    return (
        <PublicLayout 
            shop={shop}
            hasShops={shops.length > 0}
            hasPayments={payments.length > 0}
            hasForms={forms.length > 0}
            styles={style}
        >
            <div className="min-h-screen flex flex-col">
                <div className="flex-grow">

                    {/* Serveis Section */}
                    <section className="mb-12 space-y-8">
                        {/* Pagaments - només si n'hi ha */}
                        {payments.length > 0 && (
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <div className="flex flex-col md:flex-row">
                                    <div className="md:w-1/4 p-8 bg-gray-100">
                                        <h3 className="text-2xl font-semibold mb-4">Pagaments</h3>
                                        <p className="text-gray-600 mb-6">
                                            Realitza els teus pagaments de forma segura i ràpida.
                                        </p>
                                        <Link 
                                            to={`/public-open-payments`}
                                            className="inline-block w-full border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-6 py-2 rounded-lg hover:bg-[#ED5B52] hover:text-white transition-colors text-center"
                                        >
                                            Pagaments →
                                        </Link>
                                    </div>
                                    <div className="md:w-3/4 bg-white p-6">
                                        {/* Slider de pagaments */}
                                        <div className="relative h-[420px]">
                                            <div className="overflow-x-auto scrollbar-hide h-full py-2">
                                                <div 
                                                    className="flex gap-4 px-8 min-w-min h-full"
                                                    style={scrollbarHideStyles}
                                                >
                                                    {payments.map(payment => (
                                                        <div 
                                                            key={payment.id} 
                                                            className="w-[300px] flex-shrink-0 h-full py-1"
                                                        >
                                                            <div className="bg-white rounded-lg shadow p-4 h-[400px] flex flex-col">
                                                                <div className="h-40 mb-3">
                                                                    <PaymentTypeImage 
                                                                        paymentType={payment.payment_type}
                                                                        amount={payment.price}
                                                                        allowCustomAmount={payment.configuration?.allow_custom_amount}
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col flex-grow">
                                                                    <h4 className="font-medium mb-1 line-clamp-1">{payment.name}</h4>
                                                                    <p className="text-gray-600 text-sm mb-2 flex-grow line-clamp-2">
                                                                        {payment.description}
                                                                    </p>
                                                                    <div className="flex justify-between items-center">
                                                                        {!payment.configuration?.allow_custom_amount && (
                                                                            <span className="text-lg font-bold">{payment.price}€</span>
                                                                        )}
                                                                        {payment.configuration?.allow_custom_amount && (
                                                                            <span className="text-sm text-gray-500">Import personalitzable</span>
                                                                        )}
                                                                        <button 
                                                                            onClick={() => navigate(`/public-open-payments/${payment.public_hash}`)}
                                                                            className="border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-4 py-1.5 rounded hover:bg-[#ED5B52] hover:text-white transition-colors text-sm"
                                                                        >
                                                                            Realitzar pagament
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Formularis - només si n'hi ha */}
                        {forms.length > 0 && (
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <div className="flex flex-col md:flex-row">
                                    <div className="md:w-1/4 p-8 bg-gray-100">
                                        <h3 className="text-2xl font-semibold mb-4">Formularis d'inscripció</h3>
                                        <p className="text-gray-600 mb-6">
                                            Inscriu-te a cursos, activitats o serveis amb pagament integrat.
                                        </p>
                                        <Link 
                                            to={`/public-payment-forms`}
                                            className="inline-block w-full border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-6 py-2 rounded-lg hover:bg-[#ED5B52] hover:text-white transition-colors text-center"
                                        >
                                            Formularis →
                                        </Link>
                                    </div>
                                    <div className="md:w-3/4 bg-white p-6">
                                        {isLoadingForms ? (
                                            <div className="text-center py-8">Carregant formularis...</div>
                                        ) : formsError ? (
                                            <div className="text-red-500 text-center py-8">{formsError}</div>
                                        ) : forms.length === 0 ? (
                                            <div className="text-center text-gray-500 py-8">
                                                No hi ha formularis disponibles
                                            </div>
                                        ) : (
                                            <div className="relative h-[420px]">
                                                <div className="overflow-x-auto scrollbar-hide h-full py-2">
                                                    <div 
                                                        className="flex gap-4 px-8 min-w-min h-full"
                                                        style={scrollbarHideStyles}
                                                    >
                                                        {forms.map(form => (
                                                            <div 
                                                                key={form.id} 
                                                                className="w-[300px] flex-shrink-0 h-full py-1"
                                                            >
                                                                <div className="bg-white rounded-lg shadow p-4 h-[400px] flex flex-col">
                                                                    <div className="h-40 mb-3">
                                                                        <DefaultImage type="form" seed={form.name} />
                                                                    </div>
                                                                    <div className="flex flex-col flex-grow">
                                                                        <h4 className="font-medium mb-1 line-clamp-1">{form.name}</h4>
                                                                        <p className="text-gray-600 text-sm mb-2 flex-grow line-clamp-2">
                                                                            {form.description}
                                                                        </p>
                                                                        <div className="flex justify-between items-center">
                                                                            <span className="text-lg font-bold">{form.price}€</span>
                                                                            <button 
                                                                                onClick={() => navigate(`/public-payment-forms/${form.hash}`)}
                                                                                className="border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-4 py-1.5 rounded hover:bg-[#ED5B52] hover:text-white transition-colors text-sm"
                                                                            >
                                                                                Inscriu-te
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Botigues - només si n'hi ha */}
                        {shops.length > 0 && (
                            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                                <div className="flex flex-col">
                                    {/* Pestanyes de botigues */}
                                    <div className="border-b border-gray-200">
                                        <div 
                                            className="flex overflow-x-auto px-4 py-2 gap-2 scrollbar-hide" 
                                            style={scrollbarHideStyles}
                                        >
                                            {shops.map(shop => (
                                                <button
                                                    key={shop.id}
                                                    onClick={() => handleShopSelect(shop)}
                                                    className={`
                                                        flex-shrink-0 px-6 py-3 rounded-lg font-medium text-sm transition-all duration-200 whitespace-nowrap
                                                        ${selectedShop?.id === shop.id 
                                                            ? 'bg-[#ED5B52] text-white hover:bg-[#D64A41]'
                                                            : 'bg-white text-[#ED5B52] hover:bg-[#F3F4F6] border-2 border-[#ED5B52]'
                                                        }
                                                    `}
                                                >
                                                    {shop.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Contingut de la botiga seleccionada */}
                                    <div className="flex flex-col md:flex-row">
                                        <div className="md:w-1/4 p-8 bg-gray-100">
                                            <h3 className="text-2xl font-semibold mb-4">
                                                {selectedShop ? selectedShop.name : "Les nostres botigues"}
                                            </h3>
                                            <p className="text-gray-600 mb-6">
                                                {selectedShop 
                                                    ? selectedShop.description 
                                                    : "Descobreix les nostres botigues especialitzades amb tot el que necessites."
                                                }
                                            </p>
                                            {selectedShop && (
                                                <button 
                                                    onClick={() => navigate(`/shops/${selectedShop.id}`)}
                                                    className="border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-4 py-2 rounded-lg hover:bg-[#ED5B52] hover:text-white transition-colors"
                                                >
                                                    Accedeix a la botiga
                                                </button>
                                            )}
                                        </div>
                                        <div className="md:w-3/4 bg-white p-6">
                                            {selectedShop ? (
                                                <div className="space-y-8">
                                                    {/* Slider de productes */}
                                                    <div className="relative h-[420px]">
                                                        <div className="overflow-x-auto scrollbar-hide h-full py-2">
                                                            <div 
                                                                className="flex gap-4 px-8 min-w-min h-full"
                                                                style={scrollbarHideStyles}
                                                            >
                                                                {products.map(product => (
                                                                    <div 
                                                                        key={product.id} 
                                                                        className="w-[300px] flex-shrink-0 h-full py-1"
                                                                    >
                                                                        <div className="bg-white rounded-lg shadow p-4 h-[400px] flex flex-col">
                                                                            <div className="h-40 mb-3">
                                                                                {product.image ? (
                                                                                    <img
                                                                                        src={product.image}
                                                                                        alt={product.name}
                                                                                        className="w-full h-full object-cover rounded-lg"
                                                                                        onError={(e) => {
                                                                                            e.target.style.display = 'none';
                                                                                            e.target.parentElement.innerHTML = 
                                                                                                `<div class="w-full h-full">
                                                                                                    ${DefaultImage({ type: 'product', seed: product.name })}
                                                                                                </div>`;
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <DefaultImage type="product" seed={product.name} />
                                                                                )}
                                                                            </div>
                                                                            <div className="flex flex-col flex-grow">
                                                                                <h4 className="font-medium mb-1 line-clamp-1">{product.name}</h4>
                                                                                <p className="text-gray-600 text-sm mb-2 flex-grow line-clamp-2">
                                                                                    {product.description}
                                                                                </p>
                                                                                <div className="flex justify-between items-center">
                                                                                    <span className="text-lg font-bold">{product.price}€</span>
                                                                                    <button 
                                                                                        onClick={() => navigate(`/shops/${selectedShop.id}`)}
                                                                                        className="border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-4 py-1.5 rounded hover:bg-[#ED5B52] hover:text-white transition-colors text-sm"
                                                                                    >
                                                                                        Comprar
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="text-center text-gray-500 py-8">
                                                    Selecciona una botiga per veure els seus productes
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </section>
                </div>
            </div>
        </PublicLayout>
    );
};

export default withPublicStyles(PublicPlace); 